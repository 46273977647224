
var swiper = new Swiper(".swiper", {
  effect: "coverflow",
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: "auto",
  coverflowEffect: {
    rotate: 100,
    stretch: 5,
    depth: 5,
    modifier: 1,
    slideShadows: true,
  },
  pagination: {
    el: ".swiper-pagination",
  },
  autoplay: {
      delay:5000
  },
  loop: true,
});

document.addEventListener('DOMContentLoaded', function() { 
    iniciarApp();
});

function iniciarApp() {
  crearGaleria();
}

function crearGaleria() { 
     const galeria = document.querySelector('.galeria-imagenes');

     for(let i = 1; i <= 4; i++ ) {
        const imagen = document.createElement('picture');
        imagen.innerHTML = `
        <source srcset="build/img/thumb/${i}.avif" type="image/avif">
        <source srcset="build/img/thumb/${i}.webp" type="image/webp">
        <img loading="lazy" width="200" height="300"  src="build/img/thumb/${i}.jpg" alt="imagen galeria">

        `;
        imagen.onclick = function() {
          mostrarImagen(i);
        }

        galeria.appendChild(imagen);
     }
}

function mostrarImagen(id) { 
  const imagen = document.createElement('picture');
        imagen.innerHTML = `
        <source srcset="build/img/grande/${id}.avif" type="image/avif">
        <source srcset="build/img/grande/${id}.webp" type="image/webp">
        <img loading="lazy" width="200" height="300"  src="build/img/grande/${id}.jpg" alt="imagen galeria">

        `;

        // Crea el Overlay con la imagen
        const overlay = document.createElement('DIV');
        overlay.appendChild(imagen);
        overlay.classList.add('overlay');
        overlay.onclick = function() {
          const body = document.querySelector('body');
          body.classList.remove('body');
          overlay.remove();
        }

        // Boton para cerrar el Modal
        const cerrarModal = document.createElement('P');
        cerrarModal.textContent = 'X';
        cerrarModal.classList.add('btn-cerrar');
        cerrarModal.onclick = function() {
            
            overlay.remove();
        };
        overlay.appendChild(cerrarModal);

        // Añadir al HTML
        const body = document.querySelector('body');
        body.appendChild(overlay);
}



